import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID, TransferState, makeStateKey } from '@angular/core';
import { LOCATION } from '@ng-web-apis/common';
import { EMPTY, from, Observable, of } from 'rxjs';
import { catchError, mapTo, tap } from 'rxjs/operators';
import { isPlatformServer } from '@angular/common';
import { AppConfig } from '../app-config';

const MATH_APP_CONFIG = makeStateKey<AppConfig | undefined>('MATH_APP_CONFIG');

@Injectable()
export class AppConfigService {
  private config?: AppConfig;

  constructor(
    private readonly http: HttpClient,
    private readonly transferState: TransferState,
    @Inject(LOCATION) private readonly location: Location,
    @Inject(PLATFORM_ID) private readonly platformId: NonNullable<unknown>
  ) {}

  get(): AppConfig | null {
    return this.config ? this.config : null;
  }

  init$(): Observable<void> {
    if (isPlatformServer(this.platformId)) {
      return from(import('../../../server-config')).pipe(
        tap(({ cfg }) => this.transferState.set(MATH_APP_CONFIG, cfg)),
        tap(({ cfg }) => {
          this.config = cfg;
        }),
        mapTo(undefined)
      );
    }

    if (this.transferState.hasKey(MATH_APP_CONFIG)) {
      this.config = this.transferState.get(MATH_APP_CONFIG, undefined);
      return of(undefined);
    }

    const req = this.http.get(`${this.location.origin}/assets/config.json`) as Observable<AppConfig>;

    return req.pipe(
      tap((config) => {
        this.config = config;
      }),
      mapTo(undefined),
      catchError(() => {
        return EMPTY;
      })
    );
  }
}
